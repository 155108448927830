import React from 'react';

const Cart = (props) => {
    const cart = props.cart;
    let totalPrice = 0;
    for (let i = 0; i < cart.length; i++) {
        totalPrice += cart[i].price;
    }
    return (
        <div>
            <h3>Order Summary</h3>
            <p>Total Product: {cart.length}</p>
            <p>Total Price: ${totalPrice.toFixed(2)}</p>

        </div>
    );
};

export default Cart;